<template>
  <div class="page">
    <van-nav-bar
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="$router.replace('/index')"
    >
      <template #right>
        <van-button size="mini" type="primary" class="px-2 fs-12" :disabled="text===''" @click="submit">保存</van-button>
      </template>
    </van-nav-bar>
    <div class="s1">
      <textarea v-model="text" rows="15" placeholder="请输入话术，多个话术之间用换行隔开" class="inp" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AddText',
  data() {
    return {
      text: '',
      id: ''
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  created() {
    if (this.globalData.eItem) {
      this.id = this.globalData.eItem.ID
      this.text = this.globalData.eItem.Content
    }
  },
  methods: {
    submit() {
      this.$Toast.success('保存成功')
      this.$store.commit('resetGlobalData')
      this.$router.replace('/index')
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
